import React from 'react';
import { graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Layout from '../components/layout';

export const pageQuery = graphql`
  query StaticPage($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      lastUpdated(formatString: "MMMM D, YYYY")
      title
      content {
        raw
      }
    }
  }
`;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
  },
};

export default function StaticPageTemplate({ data }) {
  return (
    <Layout
      title={data.contentfulPage.title}
      mainClasses="relative py-16 bg-white overflow-hidden"
    >
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto mb-6">
          {/* <p className="text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Introducing</p> */}
          <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            {data.contentfulPage.title}
          </h1>
          <p className="text-xl text-gray-500 leading-8">
            Last updated: {data.contentfulPage.lastUpdated}
          </p>
        </div>
        <div className="prose prose-lg text-gray-500 mx-auto">
          {documentToReactComponents(
            JSON.parse(data.contentfulPage.content.raw),
            options
          )}
        </div>
      </div>
    </Layout>
  );
}
